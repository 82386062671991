import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';

import styles from '../../styles/partials/image-sequence.scss';

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.string),
    width: PropTypes.number,
    height: PropTypes.number,
    active: PropTypes.bool,
    visible: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    items: [],
    width: null,
    height: null,
    active: true,
    visible: true,
    className: null,
};

const ImageSequence = ({ items, width, height, active, visible, className }) => {
    const itemsRef = useRef([]);

    useEffect(() => {
        anime.set(itemsRef.current, { opacity: 0 });
        let i = 0;
        let direction = 'up';
        const clock = setInterval(() => {
            anime.set(itemsRef.current, { opacity: 0 });
            anime.set(itemsRef.current[i], { opacity: 1 });
            if (direction === 'up') {
                i += 1;
                if (i >= items.length - 1) {
                    direction = 'down';
                }
            } else if (direction === 'down') {
                i -= 1;
                if (i <= 0) {
                    direction = 'up';
                }
            }
        }, 200);
        return () => {
            clearInterval(clock);
        };
    }, []);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.active]: active,
                    [styles.visible]: visible,
                    [className]: className !== null,
                },
            ])}
            style={{ width, height }}
        >
            {items.map((it, i) => (
                <img
                    className={styles.image}
                    key={`${it}-${i + 1}`}
                    ref={el => {
                        itemsRef.current[i] = el;
                    }}
                    src={it}
                    alt="Sequence"
                />
            ))}
        </div>
    );
};

ImageSequence.propTypes = propTypes;
ImageSequence.defaultProps = defaultProps;

export default ImageSequence;
