import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import logoImage from '../../img/title.svg';

import { sendEvent } from '../../lib/analytics';

import styles from '../../styles/partials/header.scss';

const propTypes = {
    height: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    height: 120,
    className: null,
};

const Header = ({ height, className }) => {
    const onClick = useCallback(() => {
        sendEvent('click_link', 'interactions');
    });
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <a
                className={styles.link}
                href="https://www.fromagesdici.com/fr/art-du-plateau/21/plateau-celebrations?utm_source=urbania&utm_medium=web&utm_campaign=fondue_musicale"
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClick}
                style={{ width: '100%', height }}
            >
                <img className={styles.image} src={logoImage} alt="Fromages d'ici" />
            </a>
        </div>
    );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
