import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import UrlImage from '../../img/url.svg';

import { sendEvent } from '../../lib/analytics';

import styles from '../../styles/partials/descriptor.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const Descriptor = ({ className }) => {
    const { cheese } = useSelector(s => s.site);
    const onClick = useCallback(() => {
        sendEvent('click_link', 'interactions');
    });
    const fromage = "Fromages d'ici.";
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.image} style={{ backgroundImage: `url('${cheese.title}')` }} />
            <div className={styles.content}>
                <p className={styles.text}>{cheese.description}</p>
                <a
                    className={styles.cta}
                    href="https://www.fromagesdici.com/fr/art-du-plateau/21/plateau-celebrations?utm_source=urbania&utm_medium=web&utm_campaign=fondue_musicale"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClick}
                >
                    <span>
                        <FormattedMessage id="cta" />
                        <span className={styles.nowrap}>
                            {fromage}
                            <img className={styles.url} src={UrlImage} alt="URL" />
                        </span>
                    </span>
                </a>
            </div>
        </div>
    );
};

Descriptor.propTypes = propTypes;
Descriptor.defaultProps = defaultProps;

export default Descriptor;
