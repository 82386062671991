import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFonts } from '@folklore/fonts';
import classNames from 'classnames';

import Header from '../partials/Header';
import Sidebar from '../partials/Sidebar';
import Overlay from '../partials/Overlay';
import Loading from '../partials/Loading';

import styles from '../../styles/layouts/main.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    isPrerender: PropTypes.bool,
    menuOpen: PropTypes.bool,
    fonts: PropTypes.shape({
        google: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        custom: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    }),
};

const defaultProps = {
    isPrerender: false,
    menuOpen: true,
    fonts: {
        google: {
            families: ['Montserrat:400,700'],
        },
        custom: {
            families: ['Sina Nova:400,700,400i,700i'],
        },
    },
};

const MainLayout = ({ fonts, children, isPrerender, menuOpen }) => {
    const { loaded: fontsLoaded } = useFonts(fonts);
    const [loading, setLoading] = useState(true);

    const showLoading = !(fontsLoaded || isPrerender);

    useEffect(() => {
        let id = null;
        if (!showLoading) {
            id = setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
        return () => {
            clearTimeout(id);
        };
    }, [showLoading]);

    const innerStyle = {
        opacity: !loading ? 1 : 0,
    };

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.menuClosed]: !menuOpen,
                },
            ])}
        >
            <div className={styles.inner}>
                <div className={styles.shell} style={innerStyle}>
                    <Header className={styles.header} />
                    <div className={styles.content}>{children}</div>
                    <div className={styles.sidebar}>
                        <Sidebar />
                    </div>
                    <Overlay />
                </div>
                <Loading visible={loading} />
            </div>
        </div>
    );
};

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const WithStateContainer = connect(({ site: { isPrerender }, layout: { menuOpen } }) => ({
    isPrerender: isPrerender || false,
    menuOpen,
}))(MainLayout);

export default WithStateContainer;
