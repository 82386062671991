import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const CaretIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="7px"
        height="12px"
        viewBox="0 0 7 12"
        className={className}
        xmlSpace="preserve"
    >
        <polyline
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="1,1 6,6 1,11 "
        />
    </svg>
);

CaretIcon.propTypes = propTypes;
CaretIcon.defaultProps = defaultProps;

export default CaretIcon;
