import { SET_SIZE, SET_MENU_OPEN } from '../actions/LayoutActions';

const initialState = {
    size: {
        width: window.innerWidth || 0,
        height: window.innerHeight || 0,
    },
    menuOpen: false,
};

const LayoutReducer = (previousState, action) => {
    let state = previousState || initialState;
    if (typeof state.hydrated === 'undefined' || !state.hydrated) {
        state = {
            ...initialState,
            ...previousState,
            hydrated: true,
        };
    }
    switch (action.type) {
        case SET_SIZE:
            return {
                ...state,
                size: {
                    ...action.payload,
                },
            };
        case SET_MENU_OPEN:
            return {
                ...state,
                menuOpen: action.payload,
            };
        default:
            return state;
    }
};

export default LayoutReducer;
