/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useAudio } from 'react-use';

import staticMp3 from '../../sounds/static-2.mp3';

import { sendEvent } from '../../lib/analytics';

import {
    setPlaylist as setPlaylistAction,
    setCheese as setCheeseAction,
} from '../../actions/SiteActions';
import { setMenuOpen as setMenuOpenAction } from '../../actions/LayoutActions';

import styles from '../../styles/partials/slider.scss';

const propTypes = {
    radioSize: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    width: PropTypes.number.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    radioSize: {},
    className: null,
};

const Slider = ({ radioSize, width, className }) => {
    const [audio, state, controls] = useAudio({
        src: staticMp3,
    });

    const [sliderHeight, setHeight] = useState(0);
    const sliderRef = useRef(null);
    const dispatch = useDispatch();
    const setPlaylist = useCallback(media => dispatch(setPlaylistAction(media)), [dispatch]);
    const setCheese = useCallback(media => dispatch(setCheeseAction(media)), [dispatch]);
    const setMenuOpen = useCallback(open => dispatch(setMenuOpenAction(open)), [dispatch]);

    const { playlists, cheeses } = useSelector(s => s.site);

    const playlistRanges = Object.keys(playlists).map(key => {
        return {
            min: parseInt(key, 10),
            max: parseInt(key, 10) + 100 / Object.keys(playlists).length,
            item: playlists[key],
        };
    });

    const cheeseRanges = Object.keys(cheeses).map(key => {
        return {
            min: parseInt(key, 10),
            max: parseInt(key, 10) + 100 / Object.keys(cheeses).length,
            item: cheeses[key],
        };
    });

    const onChange = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            const val = e.target.value;
            const { item: playlist } = playlistRanges.find(it => it.min <= val && it.max >= val);
            const { item: cheese } = cheeseRanges.find(it => it.min <= val && it.max >= val);
            setPlaylist(playlist);
            setCheese(cheese);
            sendEvent('cheese_change', 'interactions', cheese.name);
        },
        [playlistRanges, cheeseRanges, setMenuOpen, setPlaylist, setCheese],
    );

    // Reset the menu
    const onClick = useCallback(() => {
        setMenuOpen(true);
        controls.play();
    }, [setMenuOpen, controls]);

    useEffect(() => {
        const sh = radioSize.width * 0.66;
        setHeight(sh);
    }, [width, sliderRef.current, radioSize.width, radioSize.height]);

    const maxWidth = width > 1400 ? '95%' : '100%';
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={sliderRef}
        >
            <input
                className={styles.range}
                type="range"
                onChange={onChange}
                onTouchStart={onClick}
                onMouseDown={onClick}
                style={{
                    width: width < 960 ? `${sliderHeight - 20}px` : maxWidth,
                    height: width < 960 ? '80px' : '2px',
                }}
            />
            {audio}
        </div>
    );
};

Slider.propTypes = propTypes;
Slider.defaultProps = defaultProps;

export default Slider;
