import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import styles from '../../styles/partials/player.scss';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    width: 320,
    height: 380,
    className: null,
};

const Player = ({ width, height, className }) => {
    const { playlist } = useSelector(state => state.site);
    const current = playlist || {
        name: 'Miami Memory',
        src: 'https://open.spotify.com/embed/album/33u2PRETjYklC6UqxJBGW2',
    };
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            style={{ width }}
        >
            <div
                className={classNames([
                    styles.playlist,
                    {
                        [styles.current]: current.src,
                        [className]: className !== null,
                    },
                ])}
            >
                <iframe
                    title="player"
                    src={current.src}
                    width={width}
                    height={height}
                    frameBorder="0"
                    allowtransparency="true" // eslint-disable-line
                    allow="encrypted-media"
                />
            </div>
        </div>
    );
};

Player.propTypes = propTypes;
Player.defaultProps = defaultProps;

export default Player;
