import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import Header from './Header';
import Player from './Player';
import CaretIcon from '../icons/Caret';

import useWindowSize from '../../hooks/useWindowSize';
import useElementSize from '../../hooks/useElementSize';

import { setMenuOpen as setMenuOpenAction } from '../../actions/LayoutActions';

import styles from '../../styles/partials/sidebar.scss';

const propTypes = {
    headerHeight: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    headerHeight: 120,
    className: null,
};

const Sidebar = ({ headerHeight, className }) => {
    // Size
    const containerRef = useRef(null);
    const { width, height } = useWindowSize();
    const { height: bodyHeight } = useElementSize(document.body);
    // Only way to always get it right on sidebar collapse
    const desktop = width >= 960;
    const sidebarWidth = desktop ? 320 : width;

    // Menu
    const { menuOpen } = useSelector(state => state.layout);
    const dispatch = useDispatch();
    const setMenuOpen = useCallback(open => dispatch(setMenuOpenAction(open)), [dispatch]);
    const toggleMenu = useCallback(() => {
        setMenuOpen(!menuOpen);
    }, [menuOpen, setMenuOpen]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.open]: menuOpen,
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
        >
            <div className={styles.inner}>
                {desktop ? <Header height={headerHeight} /> : null}
                <Player
                    width={sidebarWidth}
                    height={(desktop ? bodyHeight : height) - headerHeight}
                />
            </div>
            <button type="button" onClick={toggleMenu} className={styles.arrow}>
                <div className={styles.circle}>
                    <CaretIcon />
                </div>
            </button>
        </div>
    );
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
