const layer = typeof window.dataLayer !== 'undefined' && window.dataLayer ? window.dataLayer : null;

export const sendEvent = (action = 'click', category = '', label = '', value = 1) => {
    // console.log('event', action, layer, window.dataLayer, window);
    if (!layer) return;

    layer.push({
        event: action,
        event_category: category,
        event_label: label,
        value,
    });
};

export default {
    sendEvent,
};
