/**
 * Constants
 */

export const SET_PLAYLIST = 'site@SET_PLAYLIST';
export const SET_CHEESE = 'site@SET_CHEESE';

export const SET_ERRORS = 'site@SET_ERRORS';
export const RESET_REQUEST = 'site@RESET_REQUEST';

/**
 * Actions creator
 */

export const setPlaylist = payload => ({
    type: SET_PLAYLIST,
    payload,
});

export const setCheese = payload => ({
    type: SET_CHEESE,
    payload,
});

export const setErrors = payload => ({
    type: SET_ERRORS,
    payload,
});

export const resetErrors = () => ({
    type: SET_ERRORS,
    payload: null,
});

export const resetRequest = () => ({
    type: RESET_REQUEST,
});
