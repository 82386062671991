import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import styles from '../../styles/partials/cheese-name.scss';

const propTypes = {
    mobile: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    mobile: false,
    className: null,
};

const CheeseName = ({ mobile, className }) => {
    const cheese = useSelector(state => state.site.cheese) || {
        name: null,
        titleCentered: null,
        titleMobile: null,
    };
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <img
                className={styles.image}
                alt={cheese.name}
                src={mobile ? cheese.titleMobile : cheese.titleCentered}
            />
        </div>
    );
};

CheeseName.propTypes = propTypes;
CheeseName.defaultProps = defaultProps;

export default CheeseName;
