import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Cheese from './Cheese';
import CheeseName from './CheeseName';
import Slider from './Slider';

import useWindowSize from '../../hooks/useWindowSize';

import styles from '../../styles/partials/radio.scss';

const getWindowSize = () => ({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
});

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const Radio = ({ className }) => {
    const first = useRef(true);
    const once = useRef(true);
    const radioRef = useRef(null);
    const iframeRef = useRef(null);
    const [radioSize, setRadioSize] = useState({});
    const { width, height } = useWindowSize();
    const { menuOpen } = useSelector(state => state.layout);
    const { cheese } = useSelector(state => state.site);

    useEffect(() => {
        const rs = radioRef.current.getBoundingClientRect();
        setRadioSize(rs);
    }, [radioRef.current, width, height]);

    useEffect(() => {
        const { winWidth } = getWindowSize();
        let timeout = null;

        if (iframeRef && first.current === false) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (winWidth < 920 && once.current === true) {
                    // window.scrollTo({
                    //     top: winHeight / 1.5,
                    //     behavior: 'smooth',
                    // });
                }
                once.current = false;
            }, 3000);
        }
        first.current = false;
        return () => {
            clearTimeout(timeout);
        };
    }, [cheese, first, once]);

    useEffect(() => {
        let interval = null;
        const iframeTimeout = null;
        iframeRef.current.style.opacity = 0;

        interval = setInterval(() => {
            if (Math.random() > 0.5) {
                iframeRef.current.style.left = '-200px';
                iframeRef.current.style.right = 'auto';
            } else {
                iframeRef.current.style.left = 'auto';
                iframeRef.current.style.right = '-200px';
            }
            iframeRef.current.style.opacity = 1;
            setTimeout(() => {
                iframeRef.current.style.opacity = 0;
            }, 3000);
        }, 20000);

        return () => {
            clearInterval(interval);
            clearTimeout(iframeTimeout);
        };
    }, []);

    return (
        <div
            className={classNames([
                styles.container,
                styles.background,
                {
                    [styles.playing]: true,
                    [styles.menuOpen]: menuOpen || width < 960,
                    [className]: className !== null,
                },
            ])}
            ref={radioRef}
        >
            <div className={classNames([styles.controls, styles.background, styles.full])}>
                <div className={classNames([styles.frame, styles.background, styles.full])}>
                    <div
                        className={classNames([styles.lightning, styles.background, styles.full])}
                    />
                    <div className={classNames([styles.lights, styles.background, styles.full])} />
                    <div className={classNames([styles.inner, styles.full])}>
                        <div className={styles.center}>
                            <div className={styles.top}>
                                <Cheese />
                                {width < 960 ? <CheeseName mobile /> : null}
                            </div>

                            <div className={styles.bottom}>
                                {width >= 960 ? <CheeseName /> : null}
                                <Slider width={width} radioSize={radioSize} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <iframe
                className={styles.giphy}
                ref={iframeRef}
                title="giphy"
                src="https://giphy.com/embed/3ohhwuXG9PrJ5ukWE8"
                width="354"
                height="480"
                frameBorder="0"
                allowFullScreen
            />
        </div>
    );
};

Radio.propTypes = propTypes;
Radio.defaultProps = defaultProps;

export default Radio;
