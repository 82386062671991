/**
 * Constants
 */
export const SET_SIZE = 'layout@SET_SIZE';
export const SET_MENU_OPEN = 'layout@SET_MENU_OPEN';

/**
 * Actions creator
 */
export const setSize = payload => ({
    type: SET_SIZE,
    payload,
});

export const setMenuOpen = payload => ({
    type: SET_MENU_OPEN,
    payload,
});
