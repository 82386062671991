import { useEffect, useState } from 'react';
import { getElementInnerSize } from '@folklore/size';
import useWindowSize from './useWindowSize';

const useElementSize = element => {
    const { width, height } = useWindowSize();
    const [elementSize, setElementSize] = useState({ width: null, height: null });

    useEffect(() => {
        if (element) {
            setElementSize(getElementInnerSize(element));
        }
    }, [width, height, setElementSize, element]);

    return {
        ...elementSize,
    };
};

export default useElementSize;
