import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { UrlGeneratorProvider, createStore } from '@folklore/react-container';
import { TrackingContainer } from '@folklore/tracking';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import reducers from '../reducers/index';
import * as AppPropTypes from '../lib/PropTypes';
import { KeysProvider } from '../contexts/KeysContext';

import site from '../data/site';

import App from './App';

const propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        PropTypes.objectOf(PropTypes.string),
    ]),
    cheeses: PropTypes.objectOf(
        PropTypes.shape({
            name: PropTypes.string,
            src: PropTypes.string,
        }),
    ),
    playlists: PropTypes.objectOf(
        PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.string,
        }),
    ),
    routes: PropTypes.objectOf(PropTypes.string),
    statusCode: AppPropTypes.statusCode,
    googleApiKey: PropTypes.string,
    spotifyApiKey: PropTypes.string,
    playlist: PropTypes.shape({
        name: PropTypes.string,
        src: PropTypes.string,
    }),
    cheese: PropTypes.shape({
        name: PropTypes.string,
        src: PropTypes.string,
    }),
};

const defaultProps = {
    locale: 'fr',
    messages: {},
    routes: {},
    statusCode: null,
    googleApiKey: null,
    spotifyApiKey: null,
    ...site,
};

const Root = ({
    locale,
    messages,
    routes,
    statusCode,
    googleApiKey,
    spotifyApiKey,
    playlists,
    cheeses,
    playlist,
    cheese,
}) => {
    const store = useMemo(
        () =>
            createStore(reducers, {
                site: {
                    statusCode,
                    playlists,
                    cheeses,
                    playlist,
                    cheese,
                    current: null,
                },
            }),
        [statusCode],
    );
    const keys = useMemo(
        () => ({
            googleApiKey,
            spotifyApiKey,
        }),
        [googleApiKey],
    );
    return (
        <ReduxProvider store={store}>
            <IntlProvider locale={locale} messages={messages[locale] || messages}>
                <BrowserRouter>
                    <UrlGeneratorProvider routes={routes}>
                        <KeysProvider keys={keys}>
                            <TrackingContainer>
                                <App />
                            </TrackingContainer>
                        </KeysProvider>
                    </UrlGeneratorProvider>
                </BrowserRouter>
            </IntlProvider>
        </ReduxProvider>
    );
};

Root.propTypes = propTypes;
Root.defaultProps = defaultProps;

export default Root;
