import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import styles from '../../styles/partials/cheese.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const Cheese = ({ className }) => {
    const cheese = useSelector(state => state.site.cheese) || {
        name: 'Fromage',
        src: 'https://www.google.com',
    };
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <img className={styles.image} alt={cheese.name} src={cheese.src} />
        </div>
    );
};

Cheese.propTypes = propTypes;
Cheese.defaultProps = defaultProps;

export default Cheese;
