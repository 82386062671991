import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import Radio from '../partials/Radio';

// import * as AppPropTypes from '../../lib/PropTypes';
import PageMeta from '../partials/PageMeta';
import Descriptor from '../partials/Descriptor';

import styles from '../../styles/pages/home.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Title',
    },
});

const propTypes = {
    // intl: AppPropTypes.intl.isRequired,
};

const HomePage = () => (
    <div className={styles.container}>
        <PageMeta title={messages.metaTitle} />
        <Radio />
        <Descriptor />
    </div>
);

HomePage.propTypes = propTypes;

const WithIntlContainer = injectIntl(HomePage);

export default WithIntlContainer;
