import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ImageSequence from './ImageSequence';

import Loading1 from '../../img/loading/loading-1.png';
import Loading2 from '../../img/loading/loading-2.png';
import Loading3 from '../../img/loading/loading-3.png';
import Loading4 from '../../img/loading/loading-4.png';
import Loading5 from '../../img/loading/loading-5.png';
import Loading6 from '../../img/loading/loading-6.png';
import Loading7 from '../../img/loading/loading-7.png';
import Loading8 from '../../img/loading/loading-8.png';
import Loading9 from '../../img/loading/loading-9.png';
import Loading10 from '../../img/loading/loading-10.png';

import styles from '../../styles/partials/loading.scss';

const propTypes = {
    visible: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    visible: true,
    className: null,
};

const Loading = ({ visible, className }) => {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.hidden]: !visible,
                    [className]: className !== null,
                },
            ])}
        >
            {visible ? (
                <ImageSequence
                    width={200}
                    height={200}
                    items={[
                        Loading1,
                        Loading2,
                        Loading3,
                        Loading4,
                        Loading5,
                        Loading6,
                        Loading7,
                        Loading8,
                        Loading9,
                        Loading10,
                    ]}
                />
            ) : null}
        </div>
    );
};

// <p>Chargement...</p>

Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;

export default Loading;
