// import basques from '../img/cheeses/basques.png';
// import chevreCoco from '../img/cheeses/chevre-coco.png';
// import coaticook from '../img/cheeses/coaticook.png';
// import fleuron from '../img/cheeses/fleuron.png';
// import haloom from '../img/cheeses/haloom.png';
// import hatley from '../img/cheeses/hatley.png';
// import labneh from '../img/cheeses/labneh.png';
// import meteorite from '../img/cheeses/meteorite.png';
// import stguillaume from '../img/cheeses/st-guillaume.png';
// import tomme from '../img/cheeses/tomme.png';

import laliberteTitle from '../img/titles/laliberte.png';
import louisduboisTitle from '../img/titles/louisdubois.png';
import hatleyTitle from '../img/titles/hatley.png';
import perronTitle from '../img/titles/perron.png';
import ermiteTitle from '../img/titles/ermite.png';

import laliberte from '../img/cheezy/laliberte-small.png';
import louisdubois from '../img/cheezy/louisdubois-small.png';
import hatley from '../img/cheezy/hatley-small.png';
import perron from '../img/cheezy/perron-small.png';
import ermite from '../img/cheezy/ermite-small.png';

import laliberteTitleCentered from '../img/titles/laliberte-centered.png';
import louisduboisTitleCentered from '../img/titles/louisdubois-centered.png';
import hatleyTitleCentered from '../img/titles/hatley-centered.png';
import perronTitleCentered from '../img/titles/perron-centered.png';
import ermiteTitleCentered from '../img/titles/ermite-centered.png';

import laliberteTitleMobile from '../img/titles/laliberte-mobile.png';
import louisduboisTitleMobile from '../img/titles/louisdubois-mobile.png';
import hatleyTitleMobile from '../img/titles/hatley-mobile.png';
import perronTitleMobile from '../img/titles/perron-mobile.png';
import ermiteTitleMobile from '../img/titles/ermite-mobile.png';

export default {
    cheeses: {
        0: {
            name: 'Laliberté',
            src: laliberte,
            title: laliberteTitle,
            titleCentered: laliberteTitleCentered,
            titleMobile: laliberteTitleMobile,
            description:
                'On commence tout en délicatesse avec une liste remplie de classiques. L’Orchestre symphonique de Montréal, Les Petits Chanteurs du Mont-Royal et autres ballades sont à l’honneur ici. Rien pour faire sursauter grand-maman ou réveiller les enfants alors qu’on termine d’emballer les cadeaux. Pour accompagner cette sélection toute en finesse, on recommande le fromage Laliberté, de type brie, qui provient de la Fromagerie du Presbytère, à Sainte-Élizabeth-de-Warwick. Pas de coïncidence ici : avec ses moins de 400 habitants, la municipalité est tout à fait paisible.',
        },
        20: {
            name: 'Louis Dubois',
            src: louisdubois,
            title: louisduboisTitle,
            titleCentered: louisduboisTitleCentered,
            titleMobile: louisduboisTitleMobile,
            description:
                'On reste dans quelque chose de relativement doux, mais avec une pointe d’originalité. Ce sont ici des artistes comme Safia Nolin, Cœur de pirate et Robert Charlebois qui ont le beau rôle. On s’éloigne de la guimauve un brin, sans pour autant se lancer sur les murs. Bref, une liste de lecture aux surprises subtiles. Pour de telles chansons, on conseille d’y aller avec Le Louis Dubois. Il s’agit d’un fromage sans lactose qui renferme une panoplie de saveurs tout en étant gentil sur le palais. Il est préparé par la Fromagerie Rang 9, établie à Plessisville.',
        },
        40: {
            name: 'Hatley',
            src: hatley,
            title: hatleyTitle,
            titleCentered: hatleyTitleCentered,
            titleMobile: hatleyTitleMobile,
            description:
                'On relève le jeu d’un cran avec cette troisième liste de lecture. Les percussions se pointent enfin le bout du nez rouge, et les épaules commencent à gigoter. Au travers des interprétations modernes des Damien Robitaille et Tricot Machine se faufilent quelques classiques, gracieuseté de Félix Leclerc et de Renée Martel, entre autres. Ce mélange entre doux et fort est tout indiqué pour servir un plateau de Chemin Hatley. Fromage bio affiné trois mois, le Chemin Hatley est produit par la Fromagerie La Station, à Compton, en Estrie. Et question de faire honneur à la municipalité ayant le nom le plus hip-hop au Québec, un hymne à la neige de Maybe Watson d’Alaclair Ensemble se glisse en fin de parcours.',
        },
        60: {
            name: 'Perron',
            src: perron,
            title: perronTitle,
            titleCentered: perronTitleCentered,
            titleMobile: perronTitleMobile,
            description:
                'Vient enfin le moment de tasser les chaises et de pousser le divan pour faire de la place dans le salon. Cette quatrième liste de lecture a du rythme et donne envie de danser. C’est ici qu’on retrouvera 23 décembre, l’une de nos chansons de Noël les plus populaires. Les habitués du temps des Fêtes que sont La Bottine souriante et Maryse Letarte nous y rendent aussi visite. Pour aller avec la tradition, on suggère le Cheddar Perron au porto. En fait, la recette de ce cheddar de Saint-Prime est beaucoup plus vieille que toutes les pièces présentées ici. Créée en 1895, elle n’est étonnamment qu’un an plus jeune que La Bolduc. La première grande chanteuse québécoise est elle aussi en vedette grâce à un remix inusité de sa pièce Ça va venir, découragez-vous pas. Aux côtés des BB et Le Couleur, elle n’est finalement pas la seule surprise ici.',
        },
        80: {
            name: 'Ermite',
            src: ermite,
            title: ermiteTitle,
            titleCentered: ermiteTitleCentered,
            titleMobile: ermiteTitleMobile,
            description:
                'Pour les partys qui lèvent et les hanches solides, cette dernière liste de lecture est de mise. Vous souvenez-vous de la fois où Les Trois Accords ont fait paraître une chanson de Noël? Et de celle où Michel Barrette s’est improvisé chanteur? Eh bien peu importe, les pièces sont ici. Parmi les chansons bien rythmées, on retrouve aussi quelques chansons à boire et du violonnage en masse. Pour suivre la cadence, on propose l’Ermite. Premier fromage bleu produit au Québec, c’est le fruit du travail des moines de l’Abbaye de Saint-Benoît-du-Lac, près de Magog.',
        },
    },
    playlists: {
        0: {
            name: 'Laliberté',
            src:
                'https://open.spotify.com/embed/playlist/4wweeNRrld4bgUYMMBZpcE?si=QdeOdyh0The4h2RMbhFY7g',
        },
        20: {
            name: 'Louis Dubois',
            src:
                'https://open.spotify.com/embed/playlist/1SRKXwPX6sTDfEu5ycK8wj?si=B_s_07ZqSv-o9qm4QHLIqw',
        },
        40: {
            name: 'Hatley',
            src:
                'https://open.spotify.com/embed/playlist/0Y0xIz3PYy2h82y8i7ciNU?si=6nfrN1q8T9KWYi4o6wuLSg',
        },
        60: {
            name: 'Perron',
            src:
                'https://open.spotify.com/embed/playlist/6kvnfiIPNxuYF4p8YJcb3Y?si=qEDyrJeiRqiBQHxzksYShw',
        },
        80: {
            name: 'Ermite',
            src:
                'https://open.spotify.com/embed/playlist/6y6ETlpUFi8Dte59uR15FG?si=76_7SDo5RdObt2aTEhXiyA',
        },
    },
    playlist: {
        name: 'Hatley',
        src:
            'https://open.spotify.com/embed/playlist/0Y0xIz3PYy2h82y8i7ciNU?si=6nfrN1q8T9KWYi4o6wuLSg',
    },
    cheese: {
        name: 'Hatley',
        src: hatley,
        title: hatleyTitle,
        titleCentered: hatleyTitleCentered,
        titleMobile: hatleyTitleMobile,
        description:
            'On relève le jeu d’un cran avec cette troisième liste de lecture. Les percussions se pointent enfin le bout du nez rouge, et les épaules commencent à gigoter. Au travers des interprétations modernes des Damien Robitaille et Tricot Machine se faufilent quelques classiques, gracieuseté de Félix Leclerc et de Renée Martel, entre autres. Ce mélange entre doux et fort est tout indiqué pour servir un plateau de Chemin Hatley. Fromage bio affiné trois mois, le Chemin Hatley est produit par la Fromagerie La Station, à Compton, en Estrie. Et question de faire honneur à la municipalité ayant le nom le plus hip-hop au Québec, un hymne à la neige de Maybe Watson d’Alaclair Ensemble se glisse en fin de parcours.',
    },
};
