export default {
    locale: 'fr',
    messages: {
        'meta.title': 'La Fondue Musicale de Noël – Fromages d’ici',
        'meta.descriiption': 'De la musique qui fond dans les oreilles',
        'meta.title_401': 'Error 401',
        'meta.title_403': 'Error 403',
        'meta.title_404': 'Error 404',
        'meta.title_500': 'Error 500',

        'errors.title_401': 'Error 401',
        'errors.description_401': 'You are not authorized to access this page.',
        'errors.title_403': 'Error 403',
        'errors.description_403': 'Access to this page is forbidden',
        'errors.title_404': 'Error 404',
        'errors.description_404': 'This page doesn’t exists',
        'errors.title_500': 'Error 500',
        'errors.description_500': 'There was an error',
        'errors.goto_home': 'Go to home',

        'content.soft': 'Pâte molle',
        'content.hard': 'Pâte ferme',
        'content.title': 'La Fondue Musicale de Noël – Fromages d’ici',

        'overlay.title': 'De la musique qui fond dans les oreilles',
        'overlay.by': 'Par ESTELLE GRIGNON',
        'overlay.description':
            '<p>Ça y est : les pneus d’hiver sont installés, la bonne soupe chaude remplace les salades fraîches et on a une vague idée de ce que sera la saison du Canadien. Pas de doute, l’hiver est arrivé.</p><p>Et surtout, on commence peu à peu à sortir les guirlandes. À quelle date est-ce qu’on a le droit de commencer à monter le sapin? Bientôt, il n’y aura que deux scénarios possibles à nos soirées : soit on s’écrase devant Ciné-cadeau, soit on voit la parenté/les amis/les collègues de travail dans un réveillon/potluck/souper raclette/party de bureau.</p><p>Dans tous les cas, si la visite est de mise, il restera deux éléments à prévoir : la trame sonore et le fromage à déposer au centre de la table de la salle à manger.</p><p>Bon, il y aura sûrement d’autres éléments logistiques à gérer. Mais au moins, <strong>URBANIA Musique</strong> et les Fromages d’ici s’occupent pour vous de ces deux incontournables. Selon l’ambiance visée, cinq listes de lecture vous sont proposées, avec leur accord parfait en fromage, du plus doux au plus relevé.</p>',

        cta:
            'Un bon conseil : dégustez nos listes de lecture en savourant les cinq fromages dans ce plateau célébrations des ',
    },
    routes: {
        home: '/',
    },
};
