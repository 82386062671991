/* eslint-disable react/no-danger */
import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
// import Cookies from 'universal-cookie';

import TitleImg from '../../img/title.svg';
import NextImg from '../../img/next.svg';
// import ArrowImg from '../../img/arrow.svg';

import { setMenuOpen as setMenuOpenAction } from '../../actions/LayoutActions';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/overlay.scss';

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const Overlay = ({ intl, className }) => {
    // const [cookies] = useState(new Cookies());
    const [open, setOpen] = useState(true);
    const [showArrow, setShowArrow] = useState(true);
    const containerRef = useRef(null);

    // Menu
    const dispatch = useDispatch();
    const setMenuOpen = useCallback(state => dispatch(setMenuOpenAction(state)), [dispatch]);
    const toggleOverlay = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(false);
            setShowArrow(false);
            setMenuOpen(true);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        [setMenuOpen, setShowArrow],
    );

    // useEffect(() => {
    // cookies.set('splash', false, { path: '/' });
    // }, []);

    return (
        <button
            className={classNames([
                styles.container,
                {
                    [styles.closed]: !open,
                    [styles.hiddenArrow]: !showArrow,
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
            type="button"
            onClick={toggleOverlay}
        >
            <div className={styles.inner}>
                <img className={styles.title} src={TitleImg} alt="Fondue musicale" />
                <div className={styles.description}>
                    <div className={styles.innerDescription}>
                        <h3 className={styles.textTitle}>
                            <FormattedMessage id="overlay.title" />
                        </h3>
                        <p className={styles.writtenBy}>
                            <span>
                                <FormattedMessage id="overlay.by" />
                            </span>
                        </p>
                        <div
                            className={styles.text}
                            dangerouslySetInnerHTML={{
                                __html: intl.formatMessage({ id: 'overlay.description' }),
                            }}
                        />
                    </div>
                    <img className={styles.next} src={NextImg} alt="Next" />
                </div>
            </div>
        </button>
    );
};

// <img className={styles.arrow} src={ArrowImg} alt="Flèche" />

Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;

export default injectIntl(Overlay);
